
const Progressbar = () => {
    return(
        <>
            <div className="pregress-box">
                <div className="flex items-center justify-between mb-3 px-4">
                    <div className="text-sm font-normal text-white"></div>
                    <div className="text-sm font-normal text-white">30m MLX</div>
                </div>
                <div className="relative w-full h-14 rounded-full mb-3 border-4 border-white overflow-hidden">
                    <div className="absolute h-full top-0 left-0 bg-gradient-to-r from-pink-500 to-cyan-600 saleProgress" style={{width: '40%'}}></div>
                </div>
                <div className="flex items-center justify-between mb-3 px-4">
                    <div className="text-sm font-normal text-white">MLX SOLD</div>
                    <div className="text-sm font-normal text-white">Hardcap</div>
                </div>
            </div>
        </>
    );
};

export default Progressbar;