import React from 'react';
import Header from './components/header';
import Herosection from './components/hero/Hero';
import SeedSale from './components/seed-sale';
import BnbInvest from './components/investment/BnbInvest';
import BusdInvest from './components/investment/BusdInvest';
import BalanceOverview from './components/investment/BalanceOverview';
import { useSelector} from "react-redux";
import { useSaleInfo } from './state/sale/hooks';
import { formatNumber } from './utils';

function Seed() {
  const { sale } = useSelector((state) => state.sale)
  useSaleInfo()

  return (
    <>
      <Header/>
      
      <Herosection/>
      
      <div className='seed-sale-section px-4 pb-8 md:pb-20'>
        <div className='container px-4 mx-auto'>
          <SeedSale/>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 md:gap-8 md:mb-8">

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Total Supply</div>
                <div className="text-sm font-normal text-white">150,000,000</div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Sale Price</div>
                <div className="text-sm font-normal text-white">$0.10</div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Sale Start</div>
                <div className="text-sm font-normal text-white">6th August</div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Sale End</div>
                <div className="text-sm font-normal text-white">20th August</div>
              </div>
            </div>

          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 md:gap-8 md:mb-8">

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Total Raised</div>
                <div className="text-sm font-normal text-white ">
                  {
                    formatNumber(sale.totalRaised)
                  }
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Total BNB Raised</div>
                <div className="text-sm font-normal text-white ">
                  {
                    formatNumber(sale.totalBNBRaised)
                  }
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Total BUSD Raised</div>
                <div className="text-sm font-normal text-white ">
                  {
                    formatNumber(sale.totalBUSDRaised)
                  }
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="h-full md:h-auto bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 rounded-2xl">
                <div className="text-md md:text-2xl font-semibold text-white mb-3">Total MLX Sold</div>
                <div className="text-sm font-normal text-white ">
                  {
                    formatNumber(sale.totalMLXSold)
                  }
                </div>
              </div>
            </div>

          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-4 md:mb-8'>
            <div className='col-span-1'>
              <BnbInvest/>
            </div>
            <div className='col-span-1'>
              <BusdInvest/>
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 place-content-center'>
            <div className='md:col-start-2 col-span-1 md:col-span-2'>
              <BalanceOverview/>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Seed;
