import {createAsyncThunk} from "@reduxjs/toolkit";
import {useContract} from "../../hooks/useContract";
import {privateSaleContract, saleContract} from "../../config";
import saleABI from "../../config/abis/saleABI.json"
import {formatNumber} from "../../utils";
import {formatEther} from "ethers/lib/utils";
import { getContract } from "../../utils/getContract";
import axios from "axios";

export const fetchSaleInfo = createAsyncThunk('sale/fetchSaleInfo', async () => {
    try {
        const saleInstance = getContract(saleContract, saleABI)
        const privateInstance = getContract(privateSaleContract, saleABI)
        const mlxPrice = await saleInstance.mlxRate();

        const system = await saleInstance.system()
        let isSaleLive = true
        try {
            const resp = await axios.get("https://360coreinc.com/api.php")
            console.log(resp.data.isSaleLive)
            isSaleLive = resp.data.isSaleLive
        } catch ( err ) {
            console.log(err)
        }
        const bnbPrice = await saleInstance.getBNBRate()
        const mlxPricePrivate = await privateInstance.mlxRate();
        const privateSystem = await privateInstance.system()

        return {
            bnbPrice: bnbPrice.toJSON(),
            sale: {
                totalBNBRaised: system.bnbRaised.toJSON(),
                totalBUSDRaised: system.busdRaised.toJSON(),
                totalMLXSold: system.mlxSold.toJSON(),
                totalRaised: system.totalRaised.toJSON(),
                mlxPrice: mlxPrice.toJSON(),
                isSaleLive
            },
            private: {
                totalBNBRaised: privateSystem.bnbRaised.toJSON(),
                totalBUSDRaised: privateSystem.busdRaised.toJSON(),
                totalMLXSold: privateSystem.mlxSold.toJSON(),
                totalRaised: privateSystem.totalRaised.toJSON(),
                mlxPrice: mlxPricePrivate.toJSON(),
                isSaleLive
            }
        }
    } catch(err) {
        console.log(err)
        return {
            totalBNBRaised: "0",
            totalBUSDRaised: "0",
            totalMLXSold: "0",
            totalRaised: "0",
        }
    }
})
