import { BASE_BSC_SCAN_URLS } from '../config'
import { nodes } from './getRpcUrl'

export const setupNetwork = async ({ library }) => {
    const provider = library.currentProvider || window.ethereum
    console.log(provider)
    if (provider) {
        const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
            });
            return true
        } catch (error) {
            console.log(`switch error: ${error}`)
            if (error.code === 4902 || error.code == "-32603") {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: 'Binance Smart Chain',
                                nativeCurrency: {
                                    name: 'BNB',
                                    symbol: 'BNB',
                                    decimals: 18,
                                },
                                rpcUrls: nodes,
                                blockExplorerUrls: [`${BASE_BSC_SCAN_URLS}/`],
                            },
                        ],
                    })
                    return true
                } catch(err) {
                    return false
                }
            }
            console.error('Failed to setup the network in Metamask:', error)
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress, tokenSymbol, tokenDecimals) => {
    const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: `https://metaple.finance/favicon.ico`,
            },
        },
    })

    return tokenAdded
}