
import './App.css';
import {Route, Routes} from "react-router-dom";
import Seed from "./Seed";
import React from "react";
import {usePollBlockNumber} from "./state/block/hooks";
import useDirectConnect from "./hooks/useDirectConnect";
import PrivateSeed from './PrivateSeed';

function App() {
  usePollBlockNumber()
  useDirectConnect()
  return (
      <Routes>
        <Route path="/" element={<Seed />} />
        <Route path="/2c17c6393771ee3048ae34d6b380c5ec" element={<PrivateSeed />} />
        <Route path="*" element={<div className='text-white'><h2>get:Invalid Path</h2></div>} />
      </Routes>
  );
}

export default App;
