import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from "../../hooks/useAuth";
import {ConnectorNames} from "../../utils/web3React";

const Modal = ({ setIsOpen, setCallback }) => {
    const connectWallet = () => toast("Wallet Successfully Connect.");
    const connectError = () => toast.error("Wallet Connection Error.");
    const {login} = useAuth()
    return(
        <>
            <div className="bg-black bg-opacity-30 w-full h-full fixed z-30" onClick={() => setIsOpen(false)} />
            <div className="modal-dialog mx-auto w-full md:max-w-md bg-white border-0 rounded-3xl shadow-lg fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-40">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 dark:border-gray-600 dark:border-opacity-30 rounded-t">
                    <h3 className="text-lg font-semibold text-black">Connect Wallet</h3>
                    <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-40 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => setIsOpen(false)}>
                        <span className="bg-transparent text-black dark:text-white hover:text-indigo-600 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                    </button>
                </div>
                <div className="relative p-6 flex-auto">
                    <p className="my-0 text-blueGray-500 text-lg leading-relaxed" />
                        <div className="grid grid-cols-2 gap-5">
                            <div className="col-span-1">
                                <button className="w-full flex items-center justify-center flex-col cursor-pointer" onClick={() => {login(ConnectorNames.Injected); setCallback(false);}}>
                                    <img src="images/meta.webp" alt="" className="w-10 h-10 mx-auto mb-4" />
                                    <div className="text-black text-xs font-semibold">Metamask</div>
                                </button>
                            </div>
                            <div className="col-span-1">
                                <button className="w-full flex items-center justify-center flex-col cursor-pointer" onClick={() => {
                                    login(ConnectorNames.WalletConnect);
                                    setCallback(false);
                                }}>
                                    <svg viewBox="0 0 40 40" width="40px" color="text" xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 mx-auto mb-4">
                                        <path d="M8.68096 12.4756C14.9323 6.39698 25.0677 6.39698 31.3191 12.4756L32.0714 13.2071C32.384 13.511 32.384 14.0038 32.0714 14.3077L29.4978 16.8103C29.3415 16.9622 29.0881 16.9622 28.9318 16.8103L27.8965 15.8036C23.5354 11.563 16.4647 11.563 12.1036 15.8036L10.9948 16.8817C10.8385 17.0336 10.5851 17.0336 10.4288 16.8817L7.85517 14.3791C7.54261 14.0752 7.54261 13.5824 7.85517 13.2785L8.68096 12.4756ZM36.6417 17.6511L38.9322 19.8783C39.2448 20.1823 39.2448 20.675 38.9322 20.979L28.6039 31.022C28.2913 31.3259 27.7846 31.3259 27.472 31.022C27.472 31.022 27.472 31.022 27.472 31.022L20.1416 23.8942C20.0634 23.8182 19.9367 23.8182 19.8586 23.8942C19.8586 23.8942 19.8586 23.8942 19.8586 23.8942L12.5283 31.022C12.2157 31.3259 11.709 31.3259 11.3964 31.022C11.3964 31.022 11.3964 31.022 11.3964 31.022L1.06775 20.9788C0.755186 20.6749 0.755186 20.1821 1.06775 19.8782L3.35833 17.6509C3.6709 17.347 4.17767 17.347 4.49024 17.6509L11.8208 24.7789C11.8989 24.8549 12.0256 24.8549 12.1038 24.7789C12.1038 24.7789 12.1038 24.7789 12.1038 24.7789L19.4339 17.6509C19.7465 17.347 20.2533 17.347 20.5658 17.6509C20.5658 17.6509 20.5658 17.6509 20.5658 17.6509L27.8964 24.7789C27.9745 24.8549 28.1012 24.8549 28.1794 24.7789L35.5098 17.6511C35.8223 17.3471 36.3291 17.3471 36.6417 17.6511Z" fill="#0369a1" />
                                    </svg>
                                    <div className="text-black text-xs font-semibold">WalletConnect</div>
                                </button>
                            </div>
                        </div>
                    <p/>
                </div>
                <div className="flex items-center flex-col justify-end px-6 py-4 border-t border-solid border-blueGray-200 dark:border-gray-600 dark:border-opacity-30 rounded-b">
                    <div className="text-sm text-sky-600 font-semibold mb-4">Haven’t got a crypto wallet yet?</div>
                    <button className="bg-sky-500 hover:bg-pink-600 text-white active:bg-sky-600 font-bold uppercase text-xs w-full px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150" type="button" onClick={() => setIsOpen(false)}>Close</button>
                </div>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
export default Modal;