import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";
import { Web3ReactProvider } from '@web3-react/core'
import {getLibrary} from "./utils/web3React";
import App from "./App";

import 'sweetalert2/src/sweetalert2.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </Web3ReactProvider>
    </React.StrictMode>
);

reportWebVitals();
