import React from 'react';
import Counter from "../counter-box";
import Progressbar from "../seed-sale/Progressbar";
import SocialIcons from "../seed-sale/SocialIcons";

const SeedSale = () => {
    // const THREE_DAYS_IN_MS = 4 * 24 * 60 * 60 * 1000;
    let today = new Date(),
    yyyy = today.getFullYear()
    let dayMonth = "8/20/"
    let time = "07:30:00"
    let saleTime = dayMonth + yyyy + " " +time+" GMT+05:30";

    const countDown = new Date(saleTime).getTime()
    const dateTimeAfterThreeDays = countDown;

    return(
        <>
            <div className="md:w-3/5 mx-auto mb-8">
                <div className="seed-sale-section bg-gradient-to-r from-cyan-500 to-pink-600 p-4 md:p-16 rounded-2xl">
                    {/* <div className="text-xl md:text-3xl text-white mb-6 text-center font-semibold md:mb-8" id="headline">Seed Sale Ends In!</div> */}
                    <Counter targetDate={dateTimeAfterThreeDays} />
                    <Progressbar/>
                    <div className="flex items-center flex-col md:flex-row justify-center space-y-3 md:space-y-0 mt-4">
                        <a className="mx-3 px-4 md:px-10 py-4 rounded-full bg-white hover:bg-pink-500 text-black hover:text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider AddMainnet" href='javascript:void(0);'>Add BSC Mainnet</a>
                        <a className="mx-3 px-4 md:px-10 py-4 rounded-full bg-black hover:bg-cyan-600 text-white hover:text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider addToken" href='javascript:void(0);'>Add Token to Metamask</a>
                    </div>
                    <SocialIcons/>
                </div>
            </div>
        </>
    );
};

export default SeedSale;