import { BigNumber, ethers } from "ethers";
import { formatEther, parseEther } from "ethers/lib/utils";
import React, { useCallback, useEffect, useState } from "react";
import { saleContract } from "../../config";
import useActiveWeb3React from "../../hooks/useWeb3";
import { useSelector } from "../../redux/store";
import { useAccountInfo, useBUSDInvest} from "../../state/account/hooks";
import { formatNumber } from "../../utils";

const BusdInvest = () =>{

    const { account } = useActiveWeb3React()
    const { busdBalance, sale: saleInfo } = useSelector((state) => state.account)
    const { sale } = useSelector((state) => state.sale)
    const [ input, setInput ] = useState(BigNumber.from(0))
    const [ formattedInput, setFormattedInput ] = useState(0)
    const [ mlxCalc, setMLXCalc ] = useState(BigNumber.from(0))
    const { investBUSD, approveBUSD } = useBUSDInvest()

    const maxInvest = useCallback(() => {
        console.log(busdBalance)
        const bal = BigNumber.from(busdBalance)
        const balWithoutGas = bal.sub(parseEther("0.1")) < parseEther("0") ? parseEther("0") : bal.sub(parseEther("0.1"))
        setInput(balWithoutGas)
        setFormattedInput(formatEther(balWithoutGas))
    }, [ setInput, busdBalance])

    const _investBUSD = useCallback(() => {
        investBUSD(input)
    }, [ input, investBUSD ])

    const _approveBUSD = useCallback(() => {
        approveBUSD( saleContract )
    }, [approveBUSD, saleContract])

    const inputChange = useCallback((ele) => {
        if ( !ele.target.value || ele.target.value == "" ) {
            setInput(parseEther("0"))
            
        } else {
            setInput(parseEther(ele.target.value))
        }
        setFormattedInput(ele.target.value)
    }, [setInput, setFormattedInput])

    useEffect(() => {
        if ( sale.mlxPrice != 0 ) {
            setMLXCalc(input.mul(parseEther("1")).div(sale.mlxPrice))
        } else {
            setMLXCalc(0)
        }
    }, [input, sale, setMLXCalc])

    useAccountInfo({account})

    return(
        <>
            <div className="bg-gradient-to-r from-pink-500 to-cyan-600 bg-opacity-10 p-6 md:p-12 rounded-2xl">
                <label className="text-lg font-normal text-white block mb-3" for="inputBNB">Enter BUSD Amount ( Availabe: <span class="busdBalance">{formatNumber(busdBalance)}</span> )</label>
                <input type="text" value={formattedInput} onChange={inputChange} id="inputBNB" placeholder="0.00" className="bg-white bg-opacity-10 h-14 w-full px-5 py-4 text-sm text-white placeholder:text-white   placeholder:text-opacity-40 tracking-wider rounded-lg outline-none focus:ring-2 focus:ring-white inputBNB"></input>
                <div className="flex items-center justify-between mt-3">
                    <div className="text-sm font-normal text-white">You will receive <span class="font-semibold mbkTokensBNB">~{formatNumber(mlxCalc)}</span> MLX</div>
                    <div className="text-sm font-normal text-white">Min $10</div>
                </div>
                <div className="flex flex-wrap items-center justify-center mt-8 md:space-x-5">
                    {
                        BigNumber.from(saleInfo.allowance).isZero() ?
                        <button
                            className="my-2 mx-2 px-4 md:px-10 py-3 md:py-4 rounded-full bg-white hover:bg-pink-500 text-black hover:text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider disabled:bg-opacity-30 MaxinvestBUSD"
                            onClick={_approveBUSD}
                            disabled={!account}
                        >
                            Approved BUSD
                        </button> : <></>
                    }
                    <button
                        className="my-2 mx-2 px-4 md:px-10 py-3 md:py-4 rounded-full bg-white hover:bg-pink-500 text-black hover:text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider disabled:bg-opacity-30 MaxinvestBUSD"
                        onClick={maxInvest}
                        disabled={!account}
                    >
                        MAX Invest
                    </button>
                    <button
                        className="my-2 mx-2 px-4 md:px-10 py-3 md:py-4 rounded-full bg-black hover:bg-cyan-600 text-white hover:text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider disabled:bg-opacity-30 investBUSD"
                        onClick={_investBUSD}
                        disabled={!account}
                    >
                        Invest BUSD
                    </button>
                </div>
            </div>
        </>
    );
};

export default BusdInvest;