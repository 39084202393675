import React from 'react';
import {saleContract} from "../../config";
import useActiveWeb3React from '../../hooks/useWeb3';
import {shortAddress} from "../../utils";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { showFulfill } from '../../utils/pageHelpers';

const Herosection = ({price="0.10", contract=saleContract}) =>{

    const { account } = useActiveWeb3React()
    const rem = window.location.href

    const url = `${rem}?referrer=${account}`
    
    return(
        <>
            <section className="hero-section relative md:h-screen overflow-hidden flex items-center px-4">
                <div className="hidden md:block bg-svg-animation absolute top-0 left-0 w-full h-full">
                    <img src="images/bg-animation.svg" alt="" />
                </div>
                <div className="container px-4 mx-auto py-10 relative">
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-20 md:mt-0">
                        <div className="col-span-1 text-left md:flex md:justify-center md:flex-col">
                        <div className="text-2xl md:text-5xl font-semibold text-white mb-6">Seed Sale</div>
                        <ul className="list-none">
                            <li className="list-item text-sm text-white font-normal mb-2">- Seed Sale Begins on 8th August, 2022.</li>
                            <li className="list-item text-sm text-white font-normal mb-2">- Seed sale starts with $ {price}</li>
                            {/* <li class="list-item text-sm text-white font-normal mb-2">- Withdrawn 25% everytime after every 3 months</li> */}
                        </ul>
                        <div className="referral-box mt-8 w-full">
                            {
                                account && <>
                                <h4 class="text-lg font-medium text-white mb-3">Referral Code</h4>
                                <CopyToClipboard text={url} onCopy={() => {
                                    showFulfill("Referral link successfully copied to clipboard.", "Referral Link")
                                }}>
                                    <div class="pl-7 pr-20 py-4 bg-slate-900 bg-opacity-20 rounded-full relative mb-5 md:w-2/4">
                                        <input type="text" name="" value={url} id="" readonly class="text-white text-sm font-medium w-full bg-transparent mx-auto whitespace-pre overflow-hidden tracking-wider" />
                                        <a class="absolute top-3 right-6 text-3xl text-pink-600"><i class="las la-copy"></i></a>
                                    </div>
                                </CopyToClipboard>
                                </>
                            }
                            <a href={`https://bscscan.com/address/${contract}`} target="_blank" className="px-4 py-3 bg-gradient-to-r from-cyan-500 to-pink-600 text-white text-sm font-medium flex items-center md:w-max rounded-lg tracking-wider"><i className="las la-link text-2xl mr-4 whitespace-pre" /> <span className="whitespace-pre overflow-hidden">Sale Contract : {shortAddress(contract)}</span></a>
                            <div className="flex flex-wrap items-center justify-start mt-5">
                                <a href="https://metaple.finance/" target="_blank" className="mx-3 my-1 px-4 md:px-10 py-4 rounded-full bg-pink-500 hover:bg-white text-white hover:text-black text-sm font-medium transition-all ease-in delay-100 tracking-wider">About Metaple</a>
                                <a href="https://docs.metaple.finance/" target="_blank" className="mx-3 my-1 px-4 md:px-10 py-4 rounded-full bg-cyan-600 hover:bg-white text-white hover:text-black text-sm font-medium transition-all ease-in delay-100 tracking-wider">Whitepaper</a>
                            </div>
                        </div>
                        </div>
                        <div className="col-span-1">
                            <div className="hidden md:flex w-3/5 relative items-center justify-center mx-auto">
                                <img src="images/metaple-coin-section-image.png" className="max-w-full h-auto object-cover animation-spin-slow" alt="" />
                                <img src="images/metaple-logo.png" className="w-auto h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-44" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Herosection;