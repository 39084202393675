import {toast} from "react-toastify";

import Swal from 'sweetalert2/dist/sweetalert2.js'

export const PENDING_MESSAGE = 'Transaction Submitted...'
export const ERROR_MESSAGE = 'Error while handling Transaction!!'
export const SUCCESS_MESSAGE = 'Transaction Confirmed!!'

export const ERROR_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const SUCCESS_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const INFO_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const showError = (message) => Swal.fire({
    title: 'Unhandled Error',
    text: message,
    icon: 'error',
    confirmButtonText: 'Close'
})
export const showFulfill = (message, title='Transaction Submitted') => Swal.fire({
    title: title,
    text: message,
    icon: 'success',
    confirmButtonText: 'Close'
})

export const showPromise = (promise, pendingMessage=PENDING_MESSAGE, errorMessage=ERROR_MESSAGE, successMessage=SUCCESS_MESSAGE) => {
    toast.promise(
        promise,
        {
            pending: pendingMessage,
            success: successMessage,
            error: errorMessage
        },
        SUCCESS_PROP
    )
}


