import React, {useCallback, useEffect, useState} from "react";
import Modal from "./Modal";
import {shortAddress} from "../../utils";
import useActiveWeb3React from "../../hooks/useWeb3";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { account } = useActiveWeb3React()

    const isConnected = useCallback((isConnected) => setIsOpen(isConnected), [setIsOpen])
    return (
        <>
            <header className="fixed z-20 top-0 left-0 w-full py-3 px-3 md:px-0 bg-gradient-to-r from-[#0592BA] to-[#C50758]">
                <div className="container px-4 mx-auto">
                    <div className="flex items-center justify-between w-full">
                        <div className="navbar-brand">
                        <a><img src="images/metaple-logo.png" className="w-auto h-16 md:h-20" alt="" /></a>
                        </div>
                        {
                            !account && <button
                                className="py-3 px-4 md:px-8 rounded-full bg-cyan-600 hover:bg-pink-600 text-white shadow-2xl transition-all ease-in delay-100 connectWallet"
                                onClick={() => setIsOpen(true)}>
                                Connect Wallet
                            </button>
                        }
                        {
                            account && <button className="py-3 px-4 md:px-8 rounded-full bg-cyan-600 hover:bg-pink-600 text-white shadow-2xl transition-all ease-in delay-100 connectWallet">
                                {shortAddress(account)}
                            </button>
                        }
                    </div>
                </div>
            </header>
            {isOpen && <Modal setIsOpen={setIsOpen} setCallback={isConnected} />}
        </>
    );
}

export default Header;