import { useCallback } from "react";
import { useSelector } from "react-redux";
import useActiveWeb3React from "../../hooks/useWeb3";
import { useAccountInfo, useMLXClaim } from "../../state/account/hooks";
import { formatNumber } from "../../utils";

const BalanceOverview = () =>{
    const { sale: saleInfo } = useSelector((state) => state.account)
    const { sale } = useSelector((state) => state.sale)
    
    const { account } = useActiveWeb3React()
    const { claimMLX, claimReferMLX } = useMLXClaim()

    const _claimMLX = useCallback(
      () => {
        claimMLX()
      },
      [account, claimMLX],
    )

    const _claimRefer = useCallback(
      () => {
        claimReferMLX()
      },
      [account, claimReferMLX],
    )
    
    

    useAccountInfo({account})

    return(
        <>
            <div className="bg-gradient-to-r from-pink-500 to-cyan-600 border-2 border-black p-5 md:p-12 rounded-2xl">
                <div className="card-header bg-transparent pb-4 md:pb-6 text-center">
                    <span className="text-xl md:text-3xl text-white text-center font-semibold mb-8">MLX Balance Overview</span>
                </div>
                <div className="card-body">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 gap-2">
                        <div className="col-span-1 text-white">Your MLX Balance</div>
                        <div className="col-span-1 text-white md:text-right font-medium whitespace-pre mb-2 md:mb-0">
                            <div><span className="mlxBalance">{formatNumber(saleInfo.mlxBought)}</span> MLX</div>
                        </div>
                        <div className="col-span-1 text-white">BNB Invested</div>
                        <div className="col-span-1 text-white md:text-right font-medium whitespace-pre mb-2 md:mb-0">
                            <b className="bnbInvested">{formatNumber(saleInfo.bnbInvested)} BNB</b>
                        </div>
                        <div className="col-span-1 text-white">BUSD Invested</div>
                        <div className="col-span-1 text-white md:text-right font-medium whitespace-pre mb-2 md:mb-0">
                            <div className="busdInvested">{formatNumber(saleInfo.busdInvested)} BUSD</div>
                        </div>
                        <div className="col-span-1 text-white">Refer Amount</div>
                        <div className="col-span-1 text-white md:text-right font-medium whitespace-pre mb-2 md:mb-0">
                            <div className="busdInvested">{formatNumber(saleInfo.referAmount)} BUSD</div>
                        </div>
                        <div className="col-span-1 text-white">Claim After</div>
                        <div className="col-span-1 text-white md:text-right font-medium whitespace-pre mb-2 md:mb-0">
                            <div className="whitespace-pre nextClaim" id="nextClaim">You can claim after sale ends</div>
                        </div>
                    </div>
                </div>
                {
                    !sale.isSaleLive ?
                    <div class="mt-5">
                        <button
                            class="px-4 md:px-10 py-4 rounded-full bg-black hover:bg-[#F288AF] text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider disabled:bg-opacity-30 claimTDOX w-100"
                            onClick={_claimMLX}
                        >
                            Claim
                        </button>

                        <button
                            class="px-4 md:px-10 py-4 mt-4 rounded-full bg-black hover:bg-[#F288AF] text-white text-sm font-medium transition-all ease-in delay-100 tracking-wider disabled:bg-opacity-30 claimReferrals w-100"
                            onClick={_claimRefer}
                        >
                            Refer Claim
                        </button>
                    </div>
                    : <></>
                }
            </div>
        </>
    );
};

export default BalanceOverview;