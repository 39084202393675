import { busdContract, multiCallAddress, privateSaleContract, saleContract } from "../../config"
import { getContract } from "../../utils/getContract"
import multiABI from '../../config/abis/multicall.json'
import tokenABI from '../../config/abis/tokenABI.json'
import saleABI from '../../config/abis/saleABI.json'
import privateSaleABI from '../../config/abis/privateSaleABI.json'

export const fetchAccountBNBBalance = async ({address}) => {
    const multiInstance = getContract(multiCallAddress, multiABI)
    const ethBalance = await multiInstance.getEthBalance(address)
    return ethBalance.toJSON()
}

export const fetchAccountBUSDBalance = async ({address}) => {
    const busdInstance = getContract(busdContract, tokenABI)
    const busdBalance = await busdInstance.balanceOf(address)
    return busdBalance.toJSON()
}

export const fetchAccountSaleInfo = async ({address}) => {
    const saleInst = getContract(saleContract, saleABI)
    const busdInstance = getContract(busdContract, tokenABI)
    const allowance = await busdInstance.allowance(address, saleContract)
    const buyer = await saleInst.buyer(address)

    const bnbInvested = buyer.bnbInvested.toJSON()
    const busdInvested = buyer.busdInvested.toJSON()
    const mlxBought = buyer.mlxBought.toJSON()
    const referAmount = buyer.referAmount.toJSON()

    return {
        mlxBought,
        bnbInvested,
        busdInvested,
        referAmount,
        allowance: allowance.toJSON()
    }
}

export const fetchAccountPrivateSaleInfo = async ({address}) => {
    const privateSaleInst = getContract(privateSaleContract, privateSaleABI)
    const busdInstance = getContract(busdContract, tokenABI)
    const allowance = await busdInstance.allowance(address, privateSaleContract)
    const buyer = await privateSaleInst.buyer(address)

    const bnbInvested = buyer.bnbInvested.toJSON()
    const busdInvested = buyer.busdInvested.toJSON()
    const mlxBought = buyer.mlxBought.toJSON()
    const referAmount = buyer.referAmount.toJSON()

    return {
        mlxBought,
        bnbInvested,
        busdInvested,
        referAmount,
        allowance: allowance.toJSON()
    }
}
