import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatNumber } from "../../utils";
import { fetchAccountBNBBalance, fetchAccountBUSDBalance, fetchAccountPrivateSaleInfo, fetchAccountSaleInfo } from "./fetchAccountInfo";

export const fetchAccountInfo = createAsyncThunk("account/fetchAccountIn", async ( address ) => {
    try {
        const bnbBalance = await fetchAccountBNBBalance({address})
        const busdBalance = await fetchAccountBUSDBalance({address})
        const buyerInfo = await fetchAccountSaleInfo({address})
        const privateBuyerInfo = await fetchAccountPrivateSaleInfo({address})

        return {
            bnbBalance: bnbBalance,
            busdBalance: busdBalance,
            sale: {
                mlxBought: buyerInfo.mlxBought,
                busdInvested: buyerInfo.busdInvested,
                bnbInvested: buyerInfo.bnbInvested,
                referAmount: buyerInfo.referAmount,
                allowance: buyerInfo.allowance
            },
            private: {
                mlxBought: privateBuyerInfo.mlxBought,
                busdInvested: privateBuyerInfo.busdInvested,
                bnbInvested: privateBuyerInfo.bnbInvested,
                referAmount: privateBuyerInfo.referAmount,
                allowance: privateBuyerInfo.allowance
            }
        }
    } catch(err){
        console.log(err)
        return {
            bnbBalance: "0",
            busdBalance: "0",
            sale: {
                mlxBought: "0",
                busdInvested: "0",
                bnbInvested: "0",
                referAmount: "0",
            },
            private: {
                mlxBought: "0",
                busdInvested: "0",
                bnbInvested: "0",
                referAmount: "0",
            }
        }
    }
})