import BigNumber from "bignumber.js";
import {createSlice} from "@reduxjs/toolkit";
import {fetchSaleInfo} from "../../state/sale";

const initialState = {
    bnbPrice: new BigNumber(0).toJSON(),
    sale: {
        totalBNBRaised: new BigNumber(0).toJSON(),
        totalBUSDRaised: new BigNumber(0).toJSON(),
        totalMLXSold: new BigNumber(0).toJSON(),
        totalRaised: new BigNumber(0).toJSON(),
        mlxPrice: new BigNumber(0).toJSON(),
        isSaleLive: true
    },
    privateSale: {
        totalBNBRaised: new BigNumber(0).toJSON(),
        totalBUSDRaised: new BigNumber(0).toJSON(),
        totalMLXSold: new BigNumber(0).toJSON(),
        totalRaised: new BigNumber(0).toJSON(),
        mlxPrice: new BigNumber(0).toJSON(),
        isSaleLive: true
    }
}

const saleSlice = createSlice({
    name: 'sale',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchSaleInfo.fulfilled, (state, action) => {
            const data = action.payload
            state.sale = data.sale
            state.privateSale = data.private
            state.bnbPrice = data.bnbPrice
        })
    }
})

export default saleSlice.reducer