
const SocialIcons = () => {
    return(
        <>
            <div className="flex flex-wrap items-end justify-center mt-4 md:mt-8 space-x-4">
                <a className="my-1 inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50 hover:bg-cyan-500 hover:text-white transition-all duration-700" href="https://t.me/MetapleFinance" target="_blank">
                    <i className="lab la-telegram text-lg md:text-xl"></i>
                </a>
                <a className="my-1 inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50 hover:bg-cyan-500 hover:text-white transition-all duration-700" href="https://twitter.com/MetapleFinance/" target="_blank">
                    <i className="lab la-twitter text-lg md:text-xl"></i>
                </a>
                <a className="my-1 inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50 hover:bg-cyan-500 hover:text-white transition-all duration-700" href="https://metaplefinance.medium.com/" target="_blank">
                    <i className="lab la-medium text-lg md:text-xl"></i>
                </a>
                <a className="my-1 inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50 hover:bg-cyan-500 hover:text-white transition-all duration-700" href="https://github.com/MetapleFinance" target="_blank">
                    <i className="lab la-github text-lg md:text-xl"></i>
                </a>
                <a className="my-1 inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50 hover:bg-cyan-500 hover:text-white transition-all duration-700" href="https://www.facebook.com/TheMetapleFinance" target="_blank">
                    <i className="lab la-facebook text-lg md:text-xl"></i>
                </a>
            </div>
        </>
    );
};

export default SocialIcons;