import BigNumber from 'bignumber.js'
import {createSlice} from "@reduxjs/toolkit";
import { fetchAccountInfo } from '../../state/account';

const initialState = {
    bnbBalance: BigNumber(0).toJSON(),
    busdBalance: BigNumber(0).toJSON(),
    sale: {
        mlxBought: BigNumber(0).toJSON(),
        busdInvested: BigNumber(0).toJSON(),
        bnbInvested: BigNumber(0).toJSON(),
        referAmount: BigNumber(0).toJSON(),
        allowance: BigNumber(0).toJSON()
    },
    private: {
        mlxBought: BigNumber(0).toJSON(),
        busdInvested: BigNumber(0).toJSON(),
        bnbInvested: BigNumber(0).toJSON(),
        referAmount: BigNumber(0).toJSON(),
        allowance: BigNumber(0).toJSON()
    }
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAccountInfo.fulfilled, (state, action) => {
            const data = action.payload;
            state.bnbBalance = data.bnbBalance
            state.busdBalance = data.busdBalance
            state.sale = data.sale
            state.private = data.private
        })
    }
})


export default accountSlice.reducer