import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';

const ExpiredNotice = () => {
    return (
      <div className="expired-notice text-white text-center">
        {/*<span>Sale Is Live</span>
        <p>Buy now on metaple.finance</p>*/}
        <div className='text-4xl text-center text-white font-semibold leading-tight tracking-wider mb-10 relative'>Sale has successfully ended!</div>
      </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-3 mb-8">
        <div className='col-span-1'>
            <div className='bg-black bg-opacity-20 rounded-lg p-6 flex flex-col items-center justify-center'>
            <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
          </div>
          </div>
          <div className='col-span-1'>
            <div className='bg-black bg-opacity-20 rounded-lg p-6 flex flex-col items-center justify-center'>
            <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          </div>
          </div>
          <div className='bg-black bg-opacity-20 rounded-lg p-6 flex flex-col items-center justify-center'>
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          </div>
          <div className='col-span-1'>
            <div className='bg-black bg-opacity-20 rounded-lg p-6 flex flex-col items-center justify-center'>
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
          </div>
        </div>
      </div>
    );
  };

const Counter = ({ targetDate }) =>{
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return(
      <>
        <div className='text-2xl md:text-4xl text-center text-white font-semibold leading-tight tracking-wider mb-4 md:mb-10 relative'>Seed Sale Ends In</div>
        <div className="counter-box text-center text-white">
          <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </>   
    );
  }
};

export default Counter;