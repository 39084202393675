import { useCallback, useEffect, useMemo } from "react";

import { fetchAccountInfo } from "."
import { useContract, usePrivateSaleContract, useSaleContract, useTokenContract } from "../../hooks/useContract";
import { useAppDispatch } from "../../redux/store"
import { _approve, _claimMLX, _claimReferMLX, _investBNB, _investBUSD } from "../../utils/saleInvest";
import { useParams } from "react-router-dom"
import { constants } from "ethers";
import { busdContract } from "../../config";
import useActiveWeb3React from "../../hooks/useWeb3";

export const useAccountInfo = ({account}) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        if ( account ) {
            dispatch(fetchAccountInfo(account))
        }
    }, [dispatch, fetchAccountInfo, account]);
}

export const useBNBInvest = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const saleContract = useSaleContract()
    const params = new URLSearchParams(window.location.search)
    const referrer = params.get('referrer') ?? constants.AddressZero

    const investBNB = useCallback(async (amount) => {
        console.log(amount, referrer)
        const txn = await _investBNB(saleContract, amount, referrer )
        dispatch(fetchAccountInfo(account))
    }, [ saleContract, referrer, dispatch, fetchAccountInfo, account ])

    return { investBNB }
}

export const useBNBInvestPrivate = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const saleContract = usePrivateSaleContract()
    const params = new URLSearchParams(window.location.search)
    const referrer = params.get('referrer') ?? constants.AddressZero

    const investBNB = useCallback(async (amount) => {
        console.log(amount, referrer)
        const txn = await _investBNB(saleContract, amount, referrer )
        dispatch(fetchAccountInfo(account))
    }, [ saleContract, referrer, dispatch, fetchAccountInfo, account ])

    return { investBNB }
}

export const useMLXClaim = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const saleContract = useSaleContract()
    const claimMLX = useCallback(async () => {
        const txn = await _claimMLX( saleContract )
        dispatch( fetchAccountInfo(account) )
    }, [ saleContract, account, fetchAccountInfo ])

    const claimReferMLX = useCallback(async () => {
        const txn = await _claimReferMLX( saleContract )
        dispatch( fetchAccountInfo(account) )
    }, [ saleContract, account, fetchAccountInfo ])

    return { claimMLX, claimReferMLX }
}

export const useBUSDInvest = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const saleContract = useSaleContract()
    const busdInstance = useTokenContract(busdContract)
    const params = new URLSearchParams(window.location.search)
    const referrer = params.get('referrer') ?? constants.AddressZero

    const investBUSD = useCallback(async (amount) => {
        console.log(amount, referrer)
        const txn = await _investBUSD(saleContract, amount, referrer )
        dispatch(fetchAccountInfo(account))
    }, [ saleContract, referrer, dispatch, account, fetchAccountInfo ])

    const approveBUSD = useCallback(async (spender) => {
        const txn = await _approve(busdInstance, spender)
        dispatch(fetchAccountInfo(account))
    }, [busdInstance, dispatch, account, fetchAccountInfo])

    return { investBUSD, approveBUSD }
}


export const useBUSDInvestPrivate = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const saleContract = usePrivateSaleContract()
    const busdInstance = useTokenContract(busdContract)
    const params = new URLSearchParams(window.location.search)
    const referrer = params.get('referrer') ?? constants.AddressZero

    const investBUSD = useCallback(async (amount) => {
        console.log(amount, referrer)
        const txn = await _investBUSD(saleContract, amount, referrer )
        dispatch(fetchAccountInfo(account))
    }, [ saleContract, referrer, dispatch, account, fetchAccountInfo ])

    const approveBUSD = useCallback(async (spender) => {
        const txn = await _approve(busdInstance, spender)
        dispatch(fetchAccountInfo(account))
    }, [busdInstance, dispatch, account, fetchAccountInfo])

    return { investBUSD, approveBUSD }
}