import { useEffect } from 'react';
import {connectorLocalStorageKey} from "../config/context";

import useAuth from "./useAuth";
import {ConnectorNames} from "../utils/web3React";

const _binanceChainListener = async () => new Promise((resolve) => Object.defineProperty(window, 'BinanceChain', {
    get() {
        return this.bsc;
    },
    set(bsc) {
        this.bsc = bsc;
        resolve();
    },
}));
const useDirectConnect = () => {
    const { login } = useAuth();
    useEffect(() => {
        const connectorId = window.localStorage.getItem(connectorLocalStorageKey);
        if (connectorId) {
            const isConnectorBinanceChain = connectorId === ConnectorNames.BSC;
            const isBinanceChainDefined = Reflect.has(window, 'BinanceChain');
            if (isConnectorBinanceChain && !isBinanceChainDefined) {
                _binanceChainListener().then(() => login(connectorId));
                return;
            }
            login(connectorId);
        }
    }, [login]);
};
export default useDirectConnect;