import useActiveWeb3React from "./useWeb3";
import {useMemo} from "react";
import {getAddress} from "../utils/getAddress";
import {getContract} from "../utils/getContract";
import multiCallAbi from '../config/abis/multicall.json';
import saleABI from '../config/abis/saleABI.json';
import tokenABI from '../config/abis/tokenABI.json';
import {multiCallAddress, privateSaleContract, saleContract} from "../config";

export const useContract = (address, abi) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getContract(address, abi, library.getSigner()), [address, abi, library]);
}

export function useMulticallContract() {
    return useContract(multiCallAddress, multiCallAbi);
}

export function useSaleContract() {
    return useContract(saleContract, saleABI)
}

export function usePrivateSaleContract() {
    return useContract(privateSaleContract, saleABI)
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, tokenABI)
}