import { constants } from "ethers"
import { formatNumber, shortAddress } from "."
import { showError, showFulfill, showPromise } from "./pageHelpers"

export const _investBNB = async ( contractInst, amount, referrer ) => {
    try {
        console.log(`Initiaiting txn..`)
        let txn = await contractInst.investBNB( referrer, { value: amount } )
        console.log(`Transaction done`)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Sucessfully invested ${formatNumber(amount)} BNB!`, 'Transaction Confirmed')
    } catch ( err ) {
        console.log(err)
        showError(err.data ? err.data.message: err.message)
    }
}

export const _investBUSD = async ( contractInst, amount, referrer ) => {
    try {
        let txn = await contractInst.investBUSD( amount, referrer )
        console.log(`Transaction done`)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Sucessfully invested ${formatNumber(amount)} BUSD!`, 'Transaction Confirmed')
    } catch ( err ) {
        console.log(err)
        showError(err.data ? err.data.message: err.message)
    }
}

export const _approve = async ( tokenInst, spender ) => {
    try {
        let txn = await tokenInst.approve(spender, constants.MaxUint256)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Sucessfully approved BUSD!`, 'Transaction Confirmed')
    } catch (err) {
        showError(err.data ? err.data.message: err.message)
    }
}


export const _claimMLX = async ( saleContract ) => {
    try {
        let txn = await saleContract.claimMLX()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Sucessfully approved BUSD!`, 'Transaction Confirmed')
    } catch ( err ) {
        showError(err.data ? err.data.message: err.message)
    }
}

export const _claimReferMLX = async ( saleContract ) => {
    try {
        let txn = await saleContract.claimMLXReferred()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Sucessfully approved BUSD!`, 'Transaction Confirmed')
    } catch ( err ) {
        showError(err.data ? err.data.message: err.message)
    }
}

